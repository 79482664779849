import React, { useState } from "react"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MdMenu, MdClose, MdArrowDropDown } from "react-icons/md"
import NavbarItem from "./NavbarItem"

const NavGridContainer = ({ children }) => (
  <div className="grid grid-flow-col h-full items-center">{children}</div>
)

const NavBar = () => {
  const navbarData = useStaticQuery(graphql`
    query {
      navLogoQuery: allFile(
        filter: { relativePath: { eq: "Header-Logo-Crop.png" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }

      defaultLogoQuery: sanitySettings {
        logoImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      customPages: allSanityCustomPages(
        filter: { includeInMenu: { eq: true } }
      ) {
        edges {
          node {
            id
            title
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const { customPages } = navbarData
  // place custom page data into a variable in the correctstucture
  let customItems = customPages.edges.map(({ node: customPage }) => {
    return {
      name: customPage.title,
      handle: `/pages/${customPage.slug.current}`,
      key: `${customPage.id}-${customPage.slug.current}`,
    }
  })

  // add custom pages to end of "more" menu
  const dropDownItems = [
    {
      name: "Diabetes",
      handle: "/diabetes",
      key: "diabetes-dropdown",
    },
    {
      name: "Care Directory",
      handle: "/care-directory",
      key: "care-directory-dropdown",
    },

    {
      name: "Media",
      handle: "/media",
      key: "media-dropdown",
    },
  ].concat(customItems)

  const logo = navbarData?.defaultLogoQuery?.logoImage
    ? navbarData.defaultLogoQuery.logoImage.asset
    : navbarData.navLogoQuery.edges[0].node.childImageSharp

  // Links for Mobile Menu
  const navLinks = [
    {
      name: "Home",
      handle: "/",
      key: "home-mobile",
    },
    {
      name: "About Us",
      handle: "/about",
      key: "about-mobile",
    },

    {
      name: "Care Directory",
      handle: "/care-directory",
      key: "care-directory-mobile",
    },
    {
      name: "Active Living",
      handle: "/active-living",
      key: "active-living-mobile",
    },
    {
      name: "Nutrition & Plant Medicines",
      handle: "/nutrition-plant-medicines",
      key: "nutrition-plant-medicines-mobile",
    },
    {
      name: "Diabetes",
      handle: "/diabetes",
      key: "diabetes-mobile",
    },

    {
      name: "Media",
      handle: "/media",
      key: "media-mobile",
    },
    {
      name: "Contact Us",
      handle: "/contact",
      key: "contact-mobile",
    },
  ].concat(customItems)

  // Mobile Menu Logic
  const [menuVisibility, setMenuVisibility] = useState("hidden")
  const [hamburgerVisible, setHamburgerVisible] = useState(true)
  const visualMenu = "flex flex-col bg-white text-black p-4 lg:hidden"
  const toggleMenu = () => {
    if (menuVisibility === "hidden") {
      setHamburgerVisible(false)
      setMenuVisibility(visualMenu)
    }
    if (menuVisibility === visualMenu) {
      setMenuVisibility("hidden")
      setHamburgerVisible(true)
    }
  }

  const mobileNavBarItem =
    "text-black hover:bg-secondary-light hover:text-white p-2"
  return (
    <nav>
      {/* Mobile Nav */}
      <div className="flex lg:hidden bg-white text-black">
        <div className="flex w-full mx-4 items-center justify-between">
          <Link className="py-1" to="/">
            <Img className="w-20 " fluid={logo.fluid} />
          </Link>

          <button onClick={toggleMenu}>
            {hamburgerVisible ? (
              <MdMenu size="3rem" />
            ) : (
              <MdClose size="3rem" />
            )}
          </button>
        </div>
      </div>
      <div id="mobileMenu" className={menuVisibility}>
        {navLinks.map(navLink => {
          const { name, handle, key } = navLink
          return (
            <NavbarItem
              className="text-black hover:bg-secondary-light hover:text-white pt-3 pb-2 md:pt-4 md:pb-3 text-3xl border-b border-secondary-light last "
              name={name}
              handle={handle}
              key={key}
            />
          )
        })}
      </div>
      {/* Desktop Nav */}
      <div className="hidden lg:grid lg:grid-flow-col grid-cols-navLarge col-gap-2 bg-white overflow-visible text-black justify-stretch items-center mt-0  text-xs lg:text-sm">
        <NavGridContainer>
          <NavbarItem name="Home" handle="/" key="home" />
          <NavbarItem name="About Us" handle="/about" key="about" />

          <NavbarItem
            name="Active Living"
            handle="/active-living"
            key="active-living"
          />
        </NavGridContainer>

        <Link className="mx-auto pt-1" to="/">
          <Img className="w-48 -mb-20 z-10" fluid={logo.fluid} />
        </Link>
        <NavGridContainer>
          <NavbarItem
            name="Nutrition & Plant Medicines"
            handle="/nutrition-plant-medicines"
            key="nutrition-plant-medicines"
          />

          <NavbarItem name="Contact Us" handle="/contact" key="contact" />

          <NavbarItem
            name={
              <span className="flex justify-center px-2 ">
                More {<MdArrowDropDown />}
              </span>
            }
            key="more"
            dropdown={dropDownItems}
            dropdownWrap={true}
          />
        </NavGridContainer>
      </div>
    </nav>
  )
}

export default NavBar
