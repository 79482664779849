import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { MdSettings } from "react-icons/md"
import Img from "gatsby-image"

const StaffLink = ({ children, url = "/#", text, internal = false }) => {
  const styling =
    "flex items-center hover:text-secondary-dark justify-center lg:justify-start"
  const element = internal ? (
    <Link to={url} className={styling}>
      {children}
      <span className="pl-4">{text}</span>
    </Link>
  ) : (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styling}>
      {/* children is for icon component to be added */}
      {children}
      <span className="pl-4">{text}</span>
    </a>
  )

  return element
}

const StaffTitle = ({ children }) => {
  return <div className="font-bold text-2xl pb-2 mt-4 lg:mt-0">{children}</div>
}

export default () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      sanityLogo: file(relativePath: { eq: "sanity-logo.png" }) {
        id
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer className="">
      <div className="bg-secondary-light py-8">
        <div className="flex-column lg:flex justify-center">
          <div className="text-center lg:text-left md:ml-4 mb-4">
            <div className="font-bold text-2xl pb-2">Resources</div>
            <div className="flex justify-center text-left">
              <div className="flex flex-col mr-4">
                <Link className="hover:text-secondary-dark" to="/">
                  Home
                </Link>
                <Link className="hover:text-secondary-dark" to="/about">
                  About Us
                </Link>
                <Link
                  className="hover:text-secondary-dark"
                  to="/nutrition-plant-medicines"
                >
                  Nutrition & Plant Medicines
                </Link>
                <Link
                  className="hover:text-secondary-dark"
                  to="/care-directory"
                >
                  Care Directory
                </Link>
                <Link className="hover:text-secondary-dark" to="/active-living">
                  Active Living
                </Link>
              </div>
              <div className="flex flex-col pl-4 ml-4">
                <Link className="hover:text-secondary-dark" to="/diabetes">
                  Diabetes
                </Link>
                <Link
                  className="hover:text-secondary-dark"
                  to="/pages/downloads"
                >
                  Downloads
                </Link>

                <Link className="hover:text-secondary-dark" to="/media">
                  Media
                </Link>
                <Link className="hover:text-secondary-dark" to="/contact">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
          <div className="sm:mx-40">
            <div className="font-bold text-2xl pb-2 text-center lg:text-left">
              Contact
            </div>
            <div className="text-center lg:text-left">
              <div className="mb-4">
                <span className="font-bold">
                  Kiiwetinoong Diabetes Initiative
                </span>
                <br />
                c/o{" "}
                <a
                  href="https://www.shibogama.on.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" underline  hover:font-normal"
                >
                  Shibogama First Nations Council
                </a>
                <br />
                81 King St., P.O. Box 449
                <br />
                Sioux Lookout, ON
                <br />
                P8T 1A5
              </div>{" "}
              <div>
                <span className=""></span>
                <a
                  href="tel:807-737-2662"
                  className="hover:text-secondary-dark"
                >
                  Phone: 807-737-2662
                </a>
                <br />
                <a
                  href="tel:807-737-1583"
                  className="hover:text-secondary-dark"
                >
                  Fax: 807-737-1583
                </a>
              </div>
            </div>
          </div>
          <div className="text-center lg:text-left justify-center">
            <div className="font-bold text-2xl pb-2 mt-4 lg:mt-0">Staff</div>

            <StaffLink url="https://kdi.sanity.studio/" text="Admin">
              <MdSettings />
            </StaffLink>

            {/* Sanity.io Logo and Link */}

            <a
              href="https://www.sanity.io"
              target="_blank"
              rel="noopener noreferrer"
              className="grid justify-items-center lg:justify-items-start row-gap-2 mt-8 md:mt-5"
            >
              <div className=" mb-2">Structured content powered by:</div>
              <Img fixed={data.sanityLogo.childImageSharp.fixed} className="" />
            </a>
          </div>
        </div>
      </div>

      <div className="bg-secondary-dark p-4">
        <div className="grid mx-auto justify-center">
          <div className="text-center">
            © {new Date().getFullYear()}, Kiiwetinoong Diabetes Initiative
          </div>
          {/* <span className="text-secondary-light">&nbsp;|&nbsp;</span> */}
          {/* <a
            href="#"
            className="hover:font-bold"
            target="__blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a> */}
        </div>
        <div className="grid grid-flow-col justify-center mt-2">
          Website by&nbsp;
          <a
            className="underline"
            href="https://www.borealisweb.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Borealis Web Development
          </a>
        </div>
      </div>
    </footer>
  )
}
